import { ApiResponse } from "./api";

export interface ContractItemDetailsResponse extends ApiResponse {
  objectResponse: ContractItemDetails[] | null;
}
export interface ContractProductResponse extends ApiResponse {
  objectResponse: ContractProduct[] | null;
}

export interface ContractItemDetails {
  ContractId: number;
  ContractTemplateId: number;
  ApprovalStateId: number;
  Name: string;
  PaperformContract: number;
  DocsCreated: number;
  SignatureAvv: string;
  Remarks: string;
  AvvAccepted: number;
  ProductInformation: number;
  PrePublish: number;
  Privacy: null;
  AGB: number;
  Signature: string;
  NowedaCustomer: number;
  IdfNumber: number;
  ContractVersion: string;
  ContractSubmissionDate: string;
  ContractActiveDate: string;
  ShowRemarkInDoc: number;
  PwDocsCreated: number;
  PwDocsCreatedDate: string;
  DocsCreatedDate: string;
  ContractStateId: number;
  ContractState: string;
  ContractTypeId: number;
  ContractStartDate: string;
  ContractCreateDate: string;
  ContractCreatedByID: number;
  ContractUpdatedByID: number;
  PharmacyId: number;
  FirstNameOfOwner: string;
  LastNameOfOwner: string;
  FirstNameOfCreator: string;
  LastNameOfCreator: string;
  PersonId: number;
  CustomerNumber: string;
  OrderItemsList: OrderItemsList[];
}

export interface OrderItemsList {
  OrderItemId: number;
  OrderId: number;
  OrderItemStateId: number;
  Quantity: number;
  CreatedById: number;
  DateUpdate: string;
  UpdatedById: number;
  OrderItemCreationDate: string;
  OrderItemStartDate: string;
  ParentId: number | null;
  PackageId: number | null;
  QuantityRemaining: number;
  ContractId: number;
  OrderStateId: number;
  OrderState: string;
  ProductId: number;
  ProductName: string;
  PriceTypeId: number;
  TypeName: string;
  OrderItemState: string;
  PriceM: string;
  PriceE: string;
  PharmacyId: number;
  NowedaCustomer: number;
  PackageItems: OrderItemsList[] | null;
}

export interface ContractProduct {
  orderItemId: number;
  orderId: number;
  productOfferingId: number;
  orderItemStateId: number;
  quantity: number;
  createdById: number;
  updatedById: number;
  orderItemVersion: number;
  orderItemCreationDate: string;
  orderItemCreationDateS: string;
  orderItemStartDate: string;
  parentID: number;
  packageID: number;
  QuantityRemaining: number;
  partyID: number;
  contractID: number;
  contractStateID: number;
  contractState: string;
  contractStartDate: string;
  docsCreated: boolean;
  noweda_Customer: boolean;
  orderStateID: number;
  orderState: string;
  productID: number;
  productName: string;
  priceTypeID: number;
  typeName: string;
  orderItemState: string;
  priceM: number;
  sPriceM: string;
  priceE: number;
  sPriceE: string;
  pharmacyID: number;
  packageItems?: ContractProduct[];
  PersonId: number;
  monthly: number;
  productFactor: number;
  sorting: number;
  productQuantityDivisions?: number[];
}

export enum ContractStatus {
  InProgress = "übermittelt",
  Completed = "abgeschlossen",
  Canceled = "storniert",
}
