import { FilterMatchMode } from "primevue/api";
import { onUnmounted, ref } from "vue";

const keywordFilters = ref({
  global: {
    value: "",
    matchMode: FilterMatchMode.CONTAINS,
  },
  productNames: { value: "", matchMode: FilterMatchMode.IN },
});

export default (): any => {
  const clearFilters = () => {
    clearGlobalFilter();
  };

  const clearGlobalFilter = () => (keywordFilters.value["global"].value = "");

  onUnmounted(clearFilters);

  return {
    keywordFilters,
    clearFilters,
    clearGlobalFilter,
  };
};
